import React from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const CaseStudies = loadable(() => import("@components/CaseStudy/CaseStudies"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const CaseStudiesPage = ({ path, data }) => (
    <>
        <SEO
            title="Case Studies and Successfully Launched Projects by Impressit"
            titleTemplate="Impressit"
            description="Why should you choose us? Find out more about our experiences and level of expertise — read about our successfully delivered projects and see for yourself!"
            path={path}
        />
        <Layout path={path}>
            <CaseStudies data={data} />
        </Layout>
    </>
);

export default CaseStudiesPage;

export const caseStudiesPageQuery = graphql`
    query AllCaseStudiesQuery {
        allStrapiCaseStudies {
            nodes {
                backlightColor
                logo {
                    url
                }
                order
                title
                weMade
                coverImage {
                    url
                }
                metaUrl
            }
        }
    }
`;
